import Intro from './components/intro';
import Evaluate from './components/evaluate';
import Vision from './components/vision';
import ContactUs from './components/contactUs';

const AboutUsLayout = () => {
  return (
    <main>
      <section className="h-screen section">
        <div className="container h-full">
          <Intro />
        </div>
      </section>

      <section className=" bg-black">
        <div className="container lg:px-36 md:px-24 sm:px-12 py-24 h-full">
          <Evaluate />
        </div>
      </section>

      <section className="">
        <div className="container pt-20 pb-14 flex-col-center">
          <Vision />
        </div>
      </section>

      <section className="">
        <div className="container py-12 ">
          <ContactUs />
        </div>
      </section>
    </main>
  );
};

export default AboutUsLayout;
