import { useState } from "react";
import { toast } from "react-hot-toast";
import SubscribeBG from "../../../assets/images/subscribeBG.svg";
import Button from "../../../components/forms/button/button";
import Input from "../../../components/forms/input/input";
import TextIcon from "../../../components/textIcon/textIcon";
import { baseUrl } from "../../../common/api/CommonApi";
import BellIcon from "../../../assets/icons/bell-icon.svg";

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const emailBody = {
    email,
  };

  const sendEmail = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseUrl}/email/subscribe`, {
        method: "POST",
        body: JSON.stringify(emailBody),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast.success("Subscribed successfully!");
        setEmail("");
      } else {
        throw new Error("Failed to subscribe");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while subscribing");
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubscribeClick = () => {
    if (!email.trim() || !isValidEmail(email)) {
      toast.error("Please enter a valid email address");
    } else {
      sendEmail();
    }
  };

  return (
    <div
      className="relative w-full h-full bg-contain bg-no-repeat bg-center grid grid-rows-2 gap-8 pt-20"
      style={{ backgroundImage: `url(${SubscribeBG})` }}
    >
      <Input
        type="email"
        placeholder="Enter your email"
        className="text-center max-w-[150px] sm:max-w-[250px] lg:w-[300px] justify-self-center	self-end"
        size="medium"
        value={email}
        onChange={(e) => setEmail(e.target.value.trim())}
      />

      <Button
        size="large"
        className="justify-self-center	md:self-start self-end"
        onClick={handleSubscribeClick}
        disabled={loading}
      >
        <TextIcon
          text={loading ? "Subscribing..." : "Subscribe!"}
          icon={BellIcon}
          alt="Bell Icon"
          className="text-center "
        />
      </Button>
    </div>
  );
};

export default Subscribe;
