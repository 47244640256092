const Modal = ({ shouldShow, onClose, children }) => {
  return shouldShow ? (
    <div>
      <div
        className="fixed z-[1000] top-0 left-0 w-full h-full bg-black bg-opacity-60 "
        onClick={onClose}
      ></div>
      <div className="fixed z-[1001] transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 p-6 bg-white rounded-md w-[90%] max-w-[600px]">
        <button
          onClick={onClose}
          className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        >
          ✕
        </button>
        {children}
      </div>
    </div>
  ) : null;
};

/* 

*/

export default Modal;
