import React, { useState } from "react";

export default function FAQ() {
  const [selectedAccordion, setSelectedAccordion] = useState(null);

  const faqMap = new Map([
    [
      "What is The Nu Beginnings?",
      "The Nu Beginnings is a transformative brand dedicated to empowering individuals and businesses on their journey towards growth and success. We offer innovative solutions, resources, and guidance to help you embrace new beginnings, unlock your full potential, and create a positive impact in your personal and professional life.",
    ],
    [
      "What services does The Nu Beginnings provide?",
      "At The Nu Beginnings, we offer a wide range of services designed to facilitate personal and professional transformation. Our services include coaching and mentoring programs, workshops and training sessions, creative consulting, and strategic guidance. We tailor our offerings to meet the unique needs of individuals and businesses seeking growth and positive change.",
    ],
    [
      "How can The Nu Beginnings help individuals and businesses achieve their goals?",
      "The Nu Beginnings is dedicated to helping individuals and businesses achieve their goals by providing guidance, resources, and support. Through our transformative services, we empower individuals to overcome challenges, develop new skills, enhance creativity, and achieve personal and professional growth. For businesses, we offer strategic insights, innovative solutions, and tailored guidance to drive success and create a positive impact.",
    ],
    [
      "What sets The Nu Beginnings apart from other similar brands?",
      "What sets The Nu Beginnings apart is our holistic approach to transformation. We combine innovative methodologies, personalized attention, and a commitment to results. Our team of experienced professionals is passionate about guiding and empowering our clients, ensuring that they achieve sustainable growth and positive transformations. We foster a supportive and collaborative environment that nurtures talent and creativity, setting us apart from other brands in the industry.",
    ],
    [
      "How can I get started with The Nu Beginnings?",
      "Getting started with The Nu Beginnings is simple. Reach out to us through our contact page or fill out the inquiry form, and our dedicated team will connect with you. We'll discuss your needs, answer any questions you may have, and create a customized plan to initiate your transformative journey. We are excited to guide you on your path to new beginnings and growth.",
    ],
    [
      "Can you share some success stories or examples of transformations facilitated by The Nu Beginnings?",
      "Absolutely! We have numerous success stories of individuals and businesses who have experienced remarkable transformations through our programs and services. From individuals who have overcome personal obstacles and achieved their goals to businesses that have revitalized their strategies and achieved significant growth, our clients' success stories inspire us every day. Please visit our testimonials page to read firsthand accounts of these transformative journeys.",
    ],
    [
      "Who is the target audience for The Nu Beginnings?",
      "The Nu Beginnings serves a diverse range of individuals and businesses. Our services cater to professionals seeking career growth, entrepreneurs looking to launch or scale their businesses, individuals seeking personal development and self-improvement, and organizations aiming to foster a culture of innovation and growth. We work with individuals and businesses in various industries and stages of their journey, tailoring our solutions to meet their unique needs and aspirations.",
    ],
    [
      "How does The Nu Beginnings ensure client satisfaction?",
      "Client satisfaction is at the heart of everything we do at The Nu Beginnings. We strive to exceed client expectations by providing exceptional service, personalized attention, and delivering tangible results. We actively listen to our clients' needs, communicate transparently, and maintain open channels of communication throughout the entire process. Our commitment to client satisfaction drives us to continuously improve and innovate our services.",
    ],
    [
      "Are there any testimonials or reviews from previous clients?",
      "Yes, we are proud to share testimonials and reviews from previous clients who have experienced transformative outcomes through our services. These testimonials provide firsthand insights into the positive impact The Nu Beginnings has had on their personal and professional lives. Please visit our testimonials page to read these inspiring stories and discover how our programs have made a difference in the lives of our clients.",
    ],
    [
      "What is the meaning and inspiration behind The Nu Beginnings' brand name?",
      'The name "The Nu Beginnings" represents the essence of our brand. "Nu" signifies newness, freshness, and the opportunity for a fresh start. It reflects the idea of embracing change, growth, and transformation. We believe that every individual and business has the potential for a new beginning, and we are here to guide them on that journey towards positive change and success.',
    ],
    [
      "What are the core principles and values that guide The Nu Beginnings' brand?",
      "The Nu Beginnings is guided by core principles and values that shape our brand's identity. These include a commitment to innovation, growth, empowerment, integrity, and collaboration. We strive to be at the forefront of innovation, providing cutting-edge solutions and approaches. We believe in fostering growth and empowerment for individuals and businesses. Integrity is the foundation of our interactions, and we value collaboration to create meaningful and lasting impact.",
    ],
    [
      "What is the brand vision and mission of The Nu Beginnings?",
      "Our brand vision is to empower individuals and businesses to embrace new beginnings, unlock their full potential, and create a positive impact in the world. Our mission is to provide innovative solutions, resources, and guidance that inspire personal growth, foster creativity, and facilitate transformative journeys for individuals and businesses. We are driven by the belief that everyone deserves a chance for a fresh start and the opportunity to create a better future.",
    ],
    [
      "How does The Nu Beginnings foster innovation and creativity?",
      "At The Nu Beginnings, we foster innovation and creativity by cultivating an environment that encourages and values new ideas. We believe that creativity is the catalyst for positive change and growth. We provide a platform for individuals and businesses to explore innovative solutions, think outside the box, and challenge the status quo. Through our workshops, training sessions, and collaborative processes, we inspire and nurture creativity, empowering our clients to unlock their full creative potential.",
    ],
    [
      "Can The Nu Beginnings tailor its services to meet specific needs and goals?",
      "Absolutely! We understand that every individual and business is unique, with specific needs and goals. At The Nu Beginnings, we pride ourselves on our ability to customize our services to fit those specific needs. We take the time to understand your challenges, aspirations, and desired outcomes. By tailoring our programs and approaches, we ensure that our services are aligned with your goals, providing you with the most effective and impactful solutions.",
    ],
    [
      "What qualifications and expertise does The Nu Beginnings' team possess?",
      "The Nu Beginnings' team consists of highly skilled professionals with diverse backgrounds and expertise. Our team members hold relevant certifications, advanced degrees, and have extensive experience in their respective fields. We are proud to have industry experts, coaches, mentors, consultants, and specialists who are passionate about guiding and empowering our clients. Their qualifications, expertise, and dedication ensure that we deliver high-quality services and impactful outcomes.",
    ],
    [
      "How does The Nu Beginnings handle client confidentiality and data security?",
      "Client confidentiality and data security are of paramount importance to us at The Nu Beginnings. We adhere to strict privacy policies and take appropriate measures to protect the confidentiality and security of all client information. We have robust systems in place to safeguard data and ensure that it is accessible only to authorized personnel. Confidentiality agreements are also in place to maintain client privacy and build trust throughout our engagements.",
    ],
    [
      "What is the process of working with The Nu Beginnings?",
      "Working with The Nu Beginnings involves a collaborative and tailored approach. We begin by understanding your needs, goals ,, and desired outcomes through an initial consultation. Based on this assessment, we create a customized plan that outlines the specific services, timelines, and milestones. Throughout the process, we maintain open communication, provide regular progress updates, and actively seek feedback to ensure alignment and client satisfaction",
    ],
    [
      "How does The Nu Beginnings handle client confidentiality and data security?",
      "Client confidentiality and data security are of paramount importance to us at The Nu Beginnings. We adhere to strict privacy policies and take appropriate measures to protect the confidentiality and security of all client information. We have robust systems in place to safeguard data and ensure that it is accessible only to authorized personnel. Confidentiality agreements are also in place to maintain client privacy and build trust throughout our engagements.",
    ],
    [
      "What is the process of working with The Nu Beginnings?",
      "Working with The Nu Beginnings involves a collaborative and tailored approach. We begin by understanding your needs, goals, and desired outcomes through an initial consultation. Based on this assessment, we create a customized plan that outlines the specific services, timelines, and milestones. Throughout the process, we maintain open communication, provide regular progress updates, and actively seek feedback to ensure alignment and client satisfaction.",
    ],

    [
      "How can I contact The Nu Beginnings for further inquiries or consultations?",
      "We would be delighted to hear from you! To contact The Nu Beginnings, you can visit our Contact page on our website. You will find a contact form where you can provide your details and specific inquiries. Alternatively, you can reach out to us directly via phone or email, which are listed on our Contact page. Our dedicated team is ready to assist you and provide further information or schedule consultations to address your specific needs.",
    ],
    [
      "How does The Nu Beginnings support career growth and learning?",
      "At The Nu Beginnings, we are committed to fostering career growth and lifelong learning. We believe that investing in professional development is essential for personal growth, job satisfaction, and overall organizational success. We provide a range of opportunities and resources to support our employees' career progression. Our training sessions, workshops, online courses, mentorship programs, and career coaching initiatives are designed to enhance skills, expand knowledge, and keep up with the latest industry trends. We encourage our team members to take advantage of these resources and actively engage in continuous learning.",
    ],
    [
      "What types of training and development programs are offered at The Nu Beginnings?",
      "The Nu Beginnings offers a variety of training and development programs to support career growth and learning. Our programs include skills development workshops, leadership training, personal effectiveness courses, industry-specific certifications, and more. We regularly review and update our programs to ensure they align with emerging trends and meet the evolving needs of our clients. By offering a diverse range of training and development opportunities, we aim to empower individuals and organizations to thrive in their respective fields.",
    ],
    [
      "Are mentorship opportunities available at The Nu Beginnings?",
      "Yes, mentorship is a vital part of our professional development initiatives at The Nu Beginnings. We offer mentorship programs that pair individuals with experienced professionals in their respective fields. Mentors provide guidance, advice, and support to help mentees navigate their career paths, overcome challenges, and achieve their professional goals. Through this mentorship, we foster meaningful connections and create an environment that nurtures growth, learning, and success.",
    ],
    [
      "How does The Nu Beginnings promote networking and collaboration?",
      "At The Nu Beginnings, we understand the importance of networking and collaboration in today's interconnected world. We organize networking events, industry conferences, and community forums where individuals and businesses can connect, share insights, and forge valuable relationships. We believe that collaboration fuels innovation and growth, and by providing platforms for networking and collaboration, we empower our clients to expand their professional networks and tap into a wealth of knowledge and opportunities.",
    ],
    [
      "Are there opportunities for employees to take on new challenges and roles at The Nu Beginnings?",
      "Absolutely! We believe in creating a dynamic and fulfilling work environment that encourages personal and professional growth. At The Nu Beginnings, we offer our employees opportunities to take on new challenges and roles. We recognize the potential and talents of our team members and provide avenues for them to explore their interests, develop new skills, and contribute to our collective success. By offering diverse projects, cross-functional collaborations, and growth opportunities, we aim to create a culture of continuous learning and career advancement.",
    ],

    [
      "What social or community initiatives is The Nu Beginnings involved in?",
      "As part of our commitment to making a positive impact, The Nu Beginnings actively participates in social and community initiatives. We believe in giving back to society and supporting causes that align with our values. This may include volunteering, supporting charitable organizations, or partnering with community programs that promote education, empowerment, and environmental sustainability. By engaging in social and community initiatives, we aim to create a ripple effect of positive change and inspire others to do the same.",
    ],
  ]);
  const handleAccordionClick = (index) => {
    setSelectedAccordion((prevSelected) =>
      prevSelected === index ? null : index
    );
  };

  return (
    <div className="container ">
      <div className="text-4xl dark:text-white text-center py-4 mb-4 font-bold leading-3">
        FAQs
      </div>
      <div className="join join-vertical w-full gap-3 py-4 mb-4">
        {Array.from(faqMap.entries()).map(([question, answer], index) => (
          <div
            key={index}
            className={`collapse shadow-md bg-gray-100 collapse-plus join-item border border-base-300 ${
              selectedAccordion === index ? "collapse-open" : ""
            }`}
          >
            <input
              type="checkbox w-auto"
              name={`my-accordion-${index}`}
              checked={selectedAccordion === index}
              onChange={() => handleAccordionClick(index)}
            />
            <div
              className="collapse-title text-[18px] font-medium cursor-pointer "
              onClick={() => handleAccordionClick(index)}
            >
              {question}
            </div>
            <div className="collapse-content bg-white text-[16px] pt-3">
              <p>{answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
