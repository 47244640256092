import { ClassNames } from '../../utils/helper';

const TextIcon = ({
  text,
  icon,
  alt,
  iconWidth = '30px',
  iconHeight = '30px',
  className,
}) => {
  return (
    <div className={ClassNames('flex-row-center w-full gap-4', className)}>
      {text}
      <img className="w-[30px] h-[30px]" src={icon} alt={alt} />
    </div>
  );
};

export default TextIcon;
