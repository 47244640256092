const Evaluate = () => {
  return (
    <>
      <div className="text-left xl:text-9xl lg:text-8xl md:text-6xl sm:text-[3.5rem] text-[2.5rem] font-extrabold leading-96% tracking-widest  bg-gradient-to-r from-purple-800 via-blue-300 to-red-500 bg-clip-text text-transparent">
        evaluate <br /> create <br /> conserve
      </div>
    </>
  );
};

export default Evaluate;
