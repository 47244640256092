import WorkInProgressVector from '../../assets/images/work-in-progress.png';

const WorkInProgress = () => {
  return (
    <div className="w-full max-h-fit p-12 flex-col-center">
      <h1 className="text-[2rem] font-semibold text-primary-black pb-6">
        Work In Progress
      </h1>
      <img
        src={WorkInProgressVector}
        alt="work in progress"
        className="max-h-[500px]"
      />
    </div>
  );
};

export default WorkInProgress;
