import React from "react";
import jobs from "../../assets/images/career.png";

export default function CareerLayout() {
  return (
    <div className=" flex flex-col lg:flex-row gap-2 py-6 px-2 container lg:px-20">
      <img src={jobs} alt="career-page" className="my-auto lg:w-1/2 w-full h-[450px]" />

      <div className="h-full lg:w-1/2  w-full flex justify-center lg:py-32 py-10">
        <div class="max-w-sm rounded overflow-hidden shadow-lg border-1.5 border-gray-100 bg-blue-100">
          <div class="p-2">
            <p class="text-gray-700 text-base">
              If you want to join our team so kindly share your resume to us
              <a
                className="text-black break-all w-full flex justify-center p-4 font-semibold"
                href="mailto:feelfreewithnu@gmail.com"
              >
                feelfreewithnu@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
