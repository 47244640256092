import { ClassNames } from '../../../utils/helper';

const Button = ({
  type = 'button',
  variant = 'primary',
  size = 'medium',
  className,
  children,
  ...props
}) => {
  const getVariant = () => {
    switch (variant) {
      case 'primary':
        return 'btn btn-warning text-white rounded-full';
      case 'secondary':
        return 'btn btn-neutral text-white rounded-full';
      default:
        return 'bg-primary-yellow-light hover:bg-primary-yellow-dark text-primary-yellow-dark hover:text-white font-bold py-2 px-4 rounded';
    }
  };

  const getSize = () => {
    switch (size) {
      case 'small':
        return 'text-sm w-full max-w-[150px]';
      case 'medium':
        return 'text-xl w-full max-w-[200px]';
      case 'large':
        return 'text-2xl w-full max-w-[250px]';
      default:
        return 'text-base';
    }
  };

  return (
    <button
      type={type}
      className={ClassNames(getVariant(), getSize(), className)}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
