import React from 'react';
import FormHeader from './components/formHeader';

const ContactLayout = () => {
  return (
    <main>
      <section className="">
        <div className="container w-full h-full py-12">
          <FormHeader />
        </div>
      </section>
    </main>
  );
};

export default ContactLayout;
