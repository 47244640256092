import React from "react";
import "../../utils/css/responsible.css";

export default function disclosure() {
  const policySections = new Map([
    [
      "Reporting a Vulnerability",
      [
        // "To report a vulnerability, please send an email to our dedicated security team at feelfreewithnu@gmail.com.",
        " Provide a detailed description of the vulnerability, including the affected system or component, steps to reproduce, and any supporting documentation or proof-of-concept code.",
        " If possible, include your contact information for further communication regarding the disclosure.",
      ],
    ],
    [
      "Confidentiality and Responsible Disclosure",
      [
        " We appreciate your responsible disclosure and request that you do not disclose the vulnerability publicly until it has been resolved and verified by our team.",
        " Maintain the confidentiality of the vulnerability and any related communication or findings throughout the disclosure process.",
      ],
    ],
    [
      "Scope and Limitations",
      [
        " This policy applies to vulnerabilities discovered in our systems, applications, websites, and related digital assets owned or operated by The Nu Beginnings.",
        " Please refrain from conducting any destructive or disruptive actions, such as unauthorized access, data exfiltration, or denial-of-service attacks.",
      ],
    ],
    [
      "Legal Protection and Safe Harbor",
      [
        " The Nu Beginnings commits to not pursuing legal action against individuals who discover and report vulnerabilities in accordance with this Responsible Disclosure Policy.",
        " We consider your good-faith security research activities within the scope of this policy as exempt from any legal action.",
        " We will not share your personal information with third parties without your consent unless required by law.",
      ],
    ],
    [
      "Response and Resolution",
      [
        " Upon receiving your vulnerability report, our security team will acknowledge the receipt within a 24 hour.",
        " We will work diligently to investigate and validate the reported vulnerability, and we may request additional information if needed.",
        " Our team will maintain ongoing communication with you regarding the progress and resolution of the reported vulnerability.",
        " We strive to resolve vulnerabilities in a timely manner and will notify you when the vulnerability has been patched or mitigated.",
      ],
    ],
    [
      "Recognition and Rewards",
      [
        " We value the time and effort you invest in identifying vulnerabilities, and we may consider providing recognition or rewards for valid and impactful reports.",
        " The nature and extent of any recognition or reward will be at our discretion and may vary based on the severity and significance of the vulnerability.",
      ],
    ],
  ]);

  return (
    <div className="md:container xl:px-56 lg:px-40 sm:px-28 px-5 flex flex-col gap-5 md:py-[3%] py-[5%]">
      <div className="flex flex-col gap-2">
        <span className="text-3xl font-bold">
          The Nu Beginnings Responsible Disclosure Policy
        </span>
        <p>
          At The Nu Beginnings, we are committed to maintaining the security and
          privacy of our systems and user data. We value the contributions of
          security researchers and individuals who discover and report
          vulnerabilities in a responsible manner. This Responsible Disclosure
          Policy outlines the guidelines for reporting and addressing security
          vulnerabilities within our organization.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        {Array.from(policySections.entries()).map(([title, content], index) => (
          <div key={index}>
            {index === policySections.size - 1 ? (
              <h2 className="font-bold text-xl">{title}</h2>
            ) : (
              <h2 className="font-bold text-xl">{title}</h2>
            )}
            <ul className="ul-element">
              {content.map((item, itemIndex) => (
                <li key={itemIndex} className="li-element">
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-3xl font-bold">
          Nu Beginning Responsible Disclosure Policy
        </span>
        <p>
          By adhering to this Responsible Disclosure Policy, you play a crucial
          role in enhancing the security and privacy of our systems. We
          appreciate your collaboration and commitment to responsible security
          research.
          <p className="pt-2">
            If you have any questions or require further information, please
            contact our security team at{" "}
            <a
              className="italic underline text-purple-500 cursor-pointer"
              href="mailto:feelfreewithnu@gmail.com"
            >
              feelfreewithnu@gmail.com
            </a>
            .
          </p>
        </p>
      </div>
    </div>
  );
}
