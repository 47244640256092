import LogoGif from '../../../assets/icons/logo_gif.gif';

const KnowMore = () => {
  return (
    <div className="flex-col-center gap-12 py-16 max-w-[700px] mx-auto">
      <span className="bg-gray-400 block h-auto w-32 rounded-full overflow-hidden">
        <img src={LogoGif} alt="logo" />
      </span>
      <p className="md:text-xl sm:text-base text-center tracking-wide">
        To be a leading provider of holistic and innovative services that
        empower individuals and communities to overcome challenges, achieve
        their goals, and lead fulfilling and meaningful lives.
      </p>
      <a href="https://thenubeginnings.org/about">
      <span className="self-end font-bold">Know More</span></a>
    </div>
  );
};

export default KnowMore;
