import React, { useEffect, useRef } from "react";
import { ProductData } from "../constant/constant";
// import PlaceHolder from '../../../assets/images/placeholder-gallery.jpg';
import { motion, useAnimation, useInView } from "framer-motion";
import { truncateText } from "../../../utils/helper";

const Product = () => {
  return (
    <div className="space-y-12 sm:space-y-16 lg:space-y-24 mb-20">
      {ProductData.map((item) => (
        <SingleProduct key={item.id} {...item} />
      ))}
    </div>
  );
};

const SingleProduct = (props) => {
  const { name, description, image, coverImage, tagline } = props;

  const [readMore, setReadMore] = React.useState(false);

  const logoRef = useRef(null);
  const isInViewLogo = useInView(logoRef, { once: true });

  const descRef = useRef(null);
  const isInViewDesc = useInView(descRef, { once: true });

  const imageRef = useRef(null);
  const isInViewImage = useInView(imageRef, { once: true });

  const mainControll = useAnimation();

  useEffect(() => {
    if (isInViewLogo) {
      mainControll.start("visible");
    }
  }, [isInViewLogo]);

  useEffect(() => {
    if (isInViewDesc) {
      mainControll.start("visible");
    }
  }, [isInViewDesc]);

  useEffect(() => {
    if (isInViewImage) {
      mainControll.start("visible");
    }
  }, [isInViewImage]);

  return (
    <div className="grid xl:grid-cols-[30%_1fr] md:grid-cols-[40%_1fr] md:gap-8 lg:gap-20 gap-4 md:grid-rows-1 sm:grid-rows-[1fr_1fr]">
      <motion.div
        variants={{
          visible: { opacity: 1, x: 0 },
          hidden: { opacity: 0, x: 75 },
        }}
        initial="hidden"
        animate={mainControll}
        transition={{
          x: { type: "spring", stiffness: 100 },
          duration: 0.8,
        }}
        className="md:order-2 lg:self-center"
      >
        <motion.img
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 75 },
          }}
          initial="hidden"
          src={image}
          animate={mainControll}
          transition={{ duration: 0.5 }}
          ref={logoRef}
          className="inline-block max-w-[150px]  lg:pb-6 sm:text-4xl"
        />
        <h2 className="lg:text-3xl md:text-2xl font-heading font-extrabold  text-primary-black mb-4 lg:mb-2 sm:text-3xl text-2xl">
          {name}
        </h2>
        <h3 className="lg:text-[1.2rem] md:text-2xl font-heading font-medium  text-primary-black mb-4 lg:mb-6 sm:text-3xl text-2xl">
          {tagline}
        </h3>
        <p ref={descRef} className={`text-primary-black mb-6 tracking-wide`}>
          {readMore ? description : truncateText(description, 250)}
        </p>
        <span
          onClick={() => setReadMore(!readMore)}
          className="inline-block cursor-pointer font-bold py-2 text-primary-black"
        >
          Read More
        </span>
        {/* <span className="inline-block cursor-pointer font-bold px-4 py-2 bg-primary-yellow-light rounded-full">
          Know More
        </span> */}
      </motion.div>
      <motion.div
        ref={imageRef}
        variants={{
          visible: { opacity: 1, scale: 1 },
          hidden: { opacity: 0, scale: 0 },
        }}
        initial="hidden"
        animate={mainControll}
        transition={{
          x: { type: "spring", stiffness: 100 },
          duration: 0.8,
          delay: 0.2,
        }}
        className=" rounded-xl lg:self-center md:max-w-fit max-w-[300px] overflow-hidden "
      >
        <img
          src={coverImage}
          alt="placeholder"
          className="rounded-xl object-contain hover:scale-110 transition-all delay-200 ease-in"
        />
      </motion.div>
    </div>
  );
};

export default Product;
