import React from "react";
import { FaLinkedin, FaInstagram, FaFacebook } from "react-icons/fa";
import logo from "../../assets/images/team/logo.svg";
import { BsTwitterX } from "react-icons/bs";
import { useNavigate, Link } from "react-router-dom";

export default function Footer() {
  const year = new Date().getFullYear();

  const products = [
    {
      title: "Our Nu Beginnings",
      subTitle: ["Greatway", "Humans of ISRO", "Entwicklers"],
      subLink: ["https://web.greatway.to/", "https://greatway.to/humansofISRO", "/"],
    },
  ];
  const Company = [
    {
      title: "Company",
      subTitle: [
        "About - The Nu Beginnings",
        "Company News",
        "Career Opportunities",
        // "Investors",
        "Teams",
        "Responsible Disclosure",
      ],
    },
  ];
  const CompanyLinks = [
    "/about",
    "/company-news",
    "/jobs",
    "/teams",
    "/responsible-disclosure",
  ];

  const navigate = useNavigate();
  return (
    <div className="bg-[#F1F1F2] py-4 static bottom-0">
      <div className="xl:mx-52  md:mx-20 mx-5 lg:my-4 my-0 md:grid-rows-2 md:grid-cols-2 grid  lg:grid-rows-1 lg:grid-cols-4 col-span-3">
        <div className="flex flex-col lg:text-left text-center md:gap-y-20 gap-y-10 md:py-4">
          <div className="flex flex-col lg:text-left text-center  gap-y-4">
            <span className="font-bold text-xs">Help</span>
            <React.Fragment>
              <span className="text-slate-600 font-normal text-xs" o>
                <Link
                  to="/contact"
                  className="cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Contact us
                </Link>
              </span>
              <span className="text-slate-600 font-normal text-xs">
                <Link
                  to="/faq"
                  className="cursor-pointer"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  FAQ's
                </Link>
              </span>
            </React.Fragment>
          </div>
          <div className="flex flex-col lg:text-left text-center gap-1">
            <span className="font-bold text-xs">Registered Address:</span>
            <p className="text-slate-600 font-normal text-xs break-words lg:text-left  sm:text-center">
              Vinamra Khand, Gomtinagar, Lucknow, Uttar Pradesh India - 226010
            </p>
          </div>
        </div>
        {products.map((product, index) => (
          <div
            key={index}
            className="flex flex-col gap-y-4 lg:text-left text-center py-5 md:py-4"
          >
            <h2 className="text-black font-bold text-xs">{product.title}</h2>
            <div className="flex flex-col gap-y-4">
              {product.subTitle.map((subtitle, subIndex) => (
                <span
                  key={subIndex}
                  className="text-slate-600 font-normal text-xs "
                >
                  <a
                    href={product.subLink[subIndex]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer"
                  >
                    {subtitle}
                  </a>
                </span>
              ))}
            </div>
          </div>
        ))}
        {Company.map((product, index) => (
          <div
            key={index}
            className="flex flex-col gap-y-4 lg:text-left text-center py-5 md:py-4"
          >
            <h2 className="text-black font-bold text-xs">{product.title}</h2>
            <div className="flex flex-col gap-y-4">
              {product.subTitle.map((subtitle, subIndex) => (
                <span
                  key={subIndex}
                  className="text-slate-600 font-normal text-xs"
                  // onClick={() => {
                  //   navigate();
                  // }}
                >
                  <Link
                    to={CompanyLinks[subIndex]}
                    className="cursor-pointer"
                    onClick={(event) => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    {subtitle}
                  </Link>
                </span>
              ))}
            </div>
          </div>
        ))}
        <div className="lg:border-l-[1px] lg:border-gray-600 md:border-0 py-5 md:py-0">
          <div className="flex flex-col justify-between lg:gap-y-14 sm:gap-y-7 gap-y-4 lg:pl-12 pl-0">
            <div className="flex flex-col gap-y-2 items-center lg:items-start">
              <span className="text-xs flex justify-start">Follow us</span>
              <div className="flex xl:pr-4 xl:px-0 lg:px-0 md:px-16 sm:px-28 px-14 justify-between text-xl w-full">
                <a
                  href="https://www.facebook.com/thenubeginnings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
                <a
                  href="https://twitter.com/thenubeginnings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <BsTwitterX />
                </a>
                <a
                  href="https://instagram.com/thenubeginnings"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
                <a
                  href="https://www.linkedin.com/company/the-nu-beginnings/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              </div>
            </div>
            <div className="flex justify-center p-5">
              <img
                className="w-fit h-auto my-auto  cursor-pointer"
                src={logo}
                alt="logophoto"
                onClick={() => {
                  navigate("/");
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="xl:px-44 lg:px-20 md:px-16 w-full">
        <div className=" md:border-t md:border-slate-600 border-0 md:flex md:justify-between  py-2">
          <span className="text-xs flex justify-center md:justify-start">
            ©{year} NuBeginnings India Pvt. Ltd. All rights reserved.
          </span>
          <div className="gap-x-1 text-xs flex justify-center md:justify-start">
            <Link
              to="/"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              {" "}
              <span className="cursor-pointer">Privacy Policy</span>
            </Link>
            <span> |</span>{" "}
            <Link
              to="/"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              {" "}
              <span className="cursor-pointer">Terms of Use</span>{" "}
            </Link>
            <span> |</span>{" "}
            <Link
              to="/"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              {" "}
              <span className="cursor-pointer">Legal</span>{" "}
            </Link>
            <span> |</span>{" "}
            <Link
              to="/"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              {" "}
              <span className="cursor-pointer">Site Map</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
