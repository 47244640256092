import Header from "./components/header";
import KnowMore from "./components/knowMore";
import Brands from "./components/brands";
import Subscribe from "./components/subscribe";
import FAQ from "./components/faqs";
import ContactUs from "./components/contactUs";
import { useNavigate } from "react-router-dom";

const HomeLayout = () => {
  const navigate = useNavigate();
  return (
    <main className="home-layout">
      <section className="section h-screen bg-primary-gray">
        <div className="container w-full h-full">
          <Header />
        </div>
      </section>

      <section className="bg-primary-pink">
        <div className="container w-full h-full">
          <KnowMore />
        </div>
      </section>

      <section className="">
        <div className="container">
          <Brands />
        </div>
      </section>

      <section className="sm:h-[600px] h-[400px]">
        <div className="container w-full h-full">
          <Subscribe />
        </div>
      </section>

      <section className="">
        <div className="container py-12 pb-20 w-full h-full">
          <ContactUs />
        </div>
      </section>

      <section className="">
        <div className="container w-full h-full">
          <FAQ />
        </div>
        <div className="flex justify-center pb-4">
          <button
            className="btn btn-outline"
            onClick={() => {
              navigate("/faq");
            }}
          >
            See More
          </button>
        </div>
      </section>
    </main>
  );
};

export default HomeLayout;
