import WorkInProgress from '../../common/workInProgress/workInProgress';

const CompanyNewsLayout = () => {
  return (
    <main>
      <section className="md:h-[300px] sm:h-[150px] h-[100px] flex-row-center bg-[#A090D9]">
        <div className="container">
          <h1 className="lg:text-[7rem] md:text-7xl sm:text-4xl font-extrabold text-center text-white">
            Company News
          </h1>
        </div>
      </section>
      <section>
        <div className="container">
          <WorkInProgress />
        </div>
      </section>
    </main>
  );
};

export default CompanyNewsLayout;
