import React, { useState } from "react";

export default function FAQ() {
  const [selectedAccordion, setSelectedAccordion] = useState(null);

  const faq = [
    {
      question: "What is The Nu Beginnings?",
      answer:
        "The Nu Beginnings is a transformative brand dedicated to empowering individuals and businesses on their journey towards growth and success. We offer innovative solutions, resources, and guidance to help you embrace new beginnings, unlock your full potential, and create a positive impact in your personal and professional life.",
    },
    {
      question: "What services does The Nu Beginnings provide?",
      answer:
        "At The Nu Beginnings, we offer a wide range of services designed to facilitate personal and professional transformation. Our services include coaching and mentoring programs, workshops and training sessions, creative consulting, and strategic guidance. We tailor our offerings to meet the unique needs of individuals and businesses seeking growth and positive change.",
    },
    {
      question:
        "How can The Nu Beginnings help individuals and businesses achieve their goals?",
      answer:
        "The Nu Beginnings is dedicated to helping individuals and businesses achieve their goals by providing guidance, resources, and support. Through our transformative services, we empower individuals to overcome challenges, develop new skills, enhance creativity, and achieve personal and professional growth. For businesses, we offer strategic insights, innovative solutions, and tailored guidance to drive success and create a positive impact.",
    },
    {
      question: "What sets The Nu Beginnings apart from other similar brands?",
      answer:
        "What sets The Nu Beginnings apart is our holistic approach to transformation. We combine innovative methodologies, personalized attention, and a commitment to results. Our team of experienced professionals is passionate about guiding and empowering our clients, ensuring that they achieve sustainable growth and positive transformations. We foster a supportive and collaborative environment that nurtures talent and creativity, setting us apart from other brands in the industry.",
    },
    {
      question: "How can I get started with The Nu Beginnings?",
      answer:
        "Getting started with The Nu Beginnings is simple. Reach out to us through our contact page or fill out the inquiry form, and our dedicated team will connect with you. We'll discuss your needs, answer any questions you may have, and create a customized plan to initiate your transformative journey. We are excited to guide you on your path to new beginnings and growth.",
    },
    {
      question:
        "Can you share some success stories or examples of transformations facilitated by The Nu Beginnings?",
      answer:
        "Absolutely! We have numerous success stories of individuals and businesses who have experienced remarkable transformations through our programs and services. From individuals who have overcome personal obstacles and achieved their goals to businesses that have revitalized their strategies and achieved significant growth, our clients' success stories inspire us every day. Please visit our testimonials page to read firsthand accounts of these transformative journeys.",
    },
    {
      question: "Who is the target audience for The Nu Beginnings?",
      answer:
        "The Nu Beginnings serves a diverse range of individuals and businesses. Our services cater to professionals seeking career growth, entrepreneurs looking to launch or scale their businesses, individuals seeking personal development and self-improvement, and organizations aiming to foster a culture of innovation and growth. We work with individuals and businesses in various industries and stages of their journey, tailoring our solutions to meet their unique needs and aspirations.",
    },
  ];

  const handleAccordionClick = (index) => {
    setSelectedAccordion((prevSelected) =>
      prevSelected === index ? null : index
    );
  };

  return (
    <>
      <div className="text-4xl dark:text-white text-center mb-4 font-bold leading-3">
        FAQs
      </div>
      <div className="join join-vertical w-full gap-3 py-9">
        {faq.map((item, index) => (
          <div
            key={index}
            className={`collapse shadow-md bg-gray-100 collapse-plus join-item border border-base-300 ${
              selectedAccordion === index ? "collapse-open" : ""
            }`}
          >
            <input
              type="checkbox w-auto"
              name={`my-accordion-${index}`}
              checked={selectedAccordion === index}
              onChange={() => handleAccordionClick(index)}
            />
            <div
              className="collapse-title text-[18px] font-medium cursor-pointer "
              onClick={() => handleAccordionClick(index)}
            >
              {item.question}
            </div>
            <div className="collapse-content bg-white text-[16px] pt-3">
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
