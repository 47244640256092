import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { CiMenuBurger } from "react-icons/ci";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/team/logo.svg";
import "../../utils/css/navbar.css";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const titleArray = [
    { name: "About Us", link: "/about" },
    { name: "Products", link: "/products" },
    { name: "Team", link: "/teams" },
    { name: "Blogs", link: "/blogs" },
    { name: "Contact Us", link: "/contact" },
  ];
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);

  const getTitleForPath = (path) => {
    if (path === "/jobs") {
      return "Career";
    } else if (path === "/faq") {
      return "FAQs";
    } else if (path === "/company-news") {
      return "News";
    }
    const matchingTitle = titleArray.find((item) => item.link === path);
    return matchingTitle ? matchingTitle.name : "";
  };

  return (
    <nav
      className={`w-full top-0 transition-bg duration-100 ease-in-out z-50 sticky bg-white  ${
        scroll ? "shadow-md" : ""
      }`}
    >
      <div className="flex z-50  md:w-auto w-full lg:py-2.5 py-0 justify-between ">
        <div className="w-full sm:w-1/2 items-center xl:px-10 lg:px-7 md:px-6 z-50">
          <div
            className={`flex gap-x-3 w-full lg:pl-3 pl-1 lg:py-0 py-2 transition-all duration-350 ease-out`}
          >
            {currentPath !== "/*" ? (
              <>
                <img
                  className="w-fit z-50 h-12 my-auto xl:pl-20 lg:pl-16 sm:pl-10 pl-2 cursor-pointer"
                  src={logo}
                  alt="logophoto"
                  onClick={() => {
                    navigate("/");
                    window.scrollTo(0, 0);
                  }}
                />
                {currentPath !== "/" &&
                  currentPath !== "/responsible-disclosure" && (
                    <span className="h-[50px] z-50 w-[0.6px] bg-black dark:bg-white"></span>
                  )}
                <span className="my-auto w-auto z-50 md:text-xl text-base font-bold whitespace-nowrap">
                  {currentPath !== "/" && getTitleForPath(currentPath)}
                </span>
              </>
            ) : (
              <span className="my-2 md:mx-auto text-[#B35042] w-auto z-50 md:text-xl text-base font-bold whitespace-nowrap">
                Nothing
              </span>
            )}
          </div>
        </div>
        <div className="lg:flex hidden justify-around w-full 2xl:px-16 xl:px-12 lg:px-6 md:px-5">
          {titleArray.map((itr, index) => (
            <Link
              to={itr.link}
              key={index}
              className={`my-auto flex mx-auto text-base font-bold  lg:px-6 px-5 py-2.5 rounded-full cursor-pointer ${
                itr.name === "Contact Us"
                  ? " bg-primary-yellow-light hover:bg-primary-yellow-light text-white"
                  : "hover:bg-gray-300"
              }`}
              onClick={(event) => {
                window.scrollTo(0, 0);
              }}
            >
              {itr.name}
            </Link>
          ))}
        </div>
        <div
          className="text-3xl z-50 my-auto mr-2 text-black lg:hidden"
          onClick={() => setOpen(!open)}
        >
          {open ? <IoClose /> : <CiMenuBurger />}
        </div>

        {/* Mobile nav */}
        <ul
          className={`
          z-auto fixed text-black flex flex-col justify-between
        lg:hidden bg-white  w-full top-0 overflow-y-scroll bottom-0 pt-20 pb-16
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          {titleArray.map((itr, index) => (
            <li
              key={index}
              className={`my-auto  flex mx-auto text-base font-bold  lg:px-6 px-5 py-2.5 rounded-full cursor-pointer ${
                itr.name === "Contact Us"
                  ? " bg-primary-yellow-light hover:bg-none"
                  : "hover:bg-gray-300"
              }`}
            >
              <Link
                className="px-auto"
                to={itr.link}
                onClick={() => {
                  setOpen(false);
                  window.scrollTo(0, 0);
                }}
              >
                {itr.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
