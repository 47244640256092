import Button from '../forms/button/button';
import Modal from '../modal/modal';
import Input from '../forms/input/input';
import { useFormik } from 'formik';
import { contactUsSchema } from '../../utils/schema';
import TextArea from '../forms/textArea/textArea';

const ContactModal = ({ shouldShow, onClose, btnVariant = 'primary' }) => {
  return (
    <Modal shouldShow={shouldShow} onClose={onClose}>
      <Form btnVariant={btnVariant} />
    </Modal>
  );
};

const Form = ({ btnVariant }) => {
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
      },
      validationSchema: contactUsSchema,
      onSubmit: (values, action) => {
        console.log(values);
        action.resetForm();
      },
    });

  console.log('error:', errors);

  return (
    <form className="grid grid-cols-2  w-full gap-4" onSubmit={handleSubmit}>
      <Input
        label="First Name"
        name="firstName"
        type="text"
        size="medium"
        mandatory
        placeholder="Enter First Name"
        variant="secondary"
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        className="col-start-1"
        errorMsg={errors.firstName && touched.firstName ? errors.firstName : ''}
      />
      <Input
        label="Last Name"
        name="lastName"
        type="text"
        size="medium"
        placeholder="Enter Last Name"
        variant="secondary"
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        className="lg:col-start-2"
        errorMsg={errors.lastName && touched.lastName ? errors.lastName : ''}
      />
      <Input
        label="Email"
        name="email"
        type="email"
        size="medium"
        placeholder="Enter Email"
        mandatory
        variant="secondary"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        errorMsg={errors.email && touched.email ? errors.email : ''}
      />
      <Input
        label="Phone"
        name="phone"
        type="text"
        size="medium"
        placeholder="Enter Phone"
        variant="secondary"
        value={values.phone}
        onChange={handleChange}
        onBlur={handleBlur}
        className=""
        errorMsg={errors.phone && touched.phone ? errors.phone : ''}
      />
      <TextArea
        label="Message"
        name="message"
        type="text"
        size="medium"
        placeholder="Enter Message"
        mandatory
        variant="primary"
        value={values.message}
        onChange={handleChange}
        onBlur={handleBlur}
        className="col-start-1 col-end-3 max-w-none mb-6"
        errorMsg={errors.message && touched.message ? errors.message : ''}
      />
      <Button type="submit" variant={btnVariant} size="small" className="">
        Send Message
      </Button>
    </form>
  );
};

export default ContactModal;
