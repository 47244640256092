import SpiltScreens from '../../../components/splitScreens/spiltScreens';
import ContactUsImage from '../../../assets/images/about-us/contact-us-dark.svg';
import Button from '../../../components/forms/button/button';
import ContactModal from '../../../components/contactModal/contactModal';
import { useState } from 'react';

const ContactUs = () => {
  return (
    <SpiltScreens>
      <LeftScreen />
      <RightScreen />
    </SpiltScreens>
  );
};

const LeftScreen = () => {
  return (
    <div className="lg:w-[40%] md:w-1/2 max-w-[400px]">
      <img src={ContactUsImage} alt="Landing page" className="w-full" />
    </div>
  );
};

const RightScreen = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="md:w-[50%] w-full flex-col-center gap-8">
      <h3 className="md:text-[35px] xl:text-[55px] sm:text-4xl text-2xl lg:text-[45px] font-light">
        Want to get in touch?
      </h3>
      <Button
        variant="secondary"
        onClick={() => setShowModal(true)}
        size="large"
      >
        Contact Us
      </Button>
      <ContactModal
        shouldShow={showModal}
        onClose={() => setShowModal(false)}
        btnVariant="secondary"
      />
    </div>
  );
};

export default ContactUs;
