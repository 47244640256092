import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ClassNames } from "../../utils/helper";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={ClassNames(
        "absolute top-0 w-[5%] right-0  bg-opacity-0 md:hover:bg-opacity-20  bg-black h-full"
      )}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <span className="top-1/2 -translate-y-2/4 absolute right-0 pr-1 lg:right-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="white"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={ClassNames(
        "absolute top-0 w-[5%] left-0 bg-opacity-0 md:hover:bg-opacity-20  bg-black h-full z-20"
      )}
      style={{ display: "block" }}
      onClick={onClick}
    >
      <span className="top-1/2 -translate-y-2/4 absolute left-0 pl-1 lg:left-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="white"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
    </div>
  );
}
export default function SimpleSlider({ settings = null, children }) {
  const sliderSettings = settings || {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return (
    <div className="relative">
      <Slider {...sliderSettings}>{children}</Slider>
    </div>
  );
}
