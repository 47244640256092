import VisionImage from '../../../assets/images/about-us/nu-vision.svg';
import SpiltScreens from '../../../components/splitScreens/spiltScreens';

const Vision = () => {
  return (
    <SpiltScreens>
      <Text />
      <Image />
    </SpiltScreens>
  );
};

const Text = () => {
  return (
    <div className="w-full h-full">
      <h1 className="md:text-[4rem] text-[2rem] font-medium leading-[1.2] mb-10">
        Embrace Your Nu Beginnings
      </h1>
      <p className="mb-5 md:text-[1.6rem] text-base leading-[1.1] font-normal">
        To provide innovative solutions and resources that inspire personal
        growth, foster creativity, and facilitate transformative journeys for
        individuals and businesses.
      </p>
      <p className="md:text-[1.6rem] text-base leading-[1.1] font-normal">
        To empower individuals and communities to embrace new beginnings, unlock
        their full potential, and create a positive impact in the world.
      </p>
    </div>
  );
};

const Image = () => {
  return (
    <div className="w-full h-full">
      <img src={VisionImage} alt="vision" />
    </div>
  );
};

export default Vision;
