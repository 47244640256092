import React from "react";
import Input from "../../../components/forms/input/input";
import Button from "../../../components/forms/button/button";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { contactUsSchema } from "../../../utils/schema";
import TextArea from "../../../components/forms/textArea/textArea";
import SpiltScreens from "../../../components/splitScreens/spiltScreens";
import ContactUsVector from "../../../assets/images/contactUs.png";
import { baseUrl } from "../../../common/api/CommonApi";

const FormHeader = () => {
  return (
    <div className="w-full h-full">
      <SpiltScreens classNames="justify-between">
        <Form />
        <Image />
      </SpiltScreens>
    </div>
  );
};

const Image = () => {
  return (
    <div className="lg:w-[40%] md:w-[45%] md:block hidden">
      <img src={ContactUsVector} alt="contact us 24 hrs" />
    </div>
  );
};

const Form = () => {
  const [loading, setLoading] = React.useState(false);
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      },
      validationSchema: contactUsSchema,
      onSubmit: async (values, action) => {
        setLoading(true);
        try {
          console.log(values);
          await sendEmail(values);
          action.resetForm();
          toast.success("Thank you for connecting");
        } catch (error) {
          console.error(error);
          toast.error("An error occurred while sending the email");
        } finally {
          setLoading(false);
        }
      },
    });

  const sendEmail = async (dataSend) => {
    try {
      const response = await fetch(`${baseUrl}/email/sendEmail`, {
        method: "POST",
        body: JSON.stringify(dataSend),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.error(response);
        toast.error("Facing a problem");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while sending the email");
    }
  };

  return (
    <div className="md:w-[50%] w-full bg-gray-100 shadow-md rounded-md px-6 py-8">
      <form
        className="grid lg:grid-cols-2 md:grid-cols-1 w-full gap-4"
        onSubmit={handleSubmit}
      >
        <Input
          label="First Name"
          name="firstName"
          type="text"
          size="medium"
          mandatory
          placeholder="Enter First Name"
          variant="secondary"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          className="col-start-1"
          errorMsg={
            errors.firstName && touched.firstName ? errors.firstName : ""
          }
        />
        <Input
          label="Last Name"
          name="lastName"
          type="text"
          size="medium"
          placeholder="Enter Last Name"
          variant="secondary"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          className="lg:col-start-2"
          errorMsg={errors.lastName && touched.lastName ? errors.lastName : ""}
        />
        <Input
          label="Email"
          name="email"
          type="email"
          size="medium"
          placeholder="Enter Email"
          mandatory
          variant="secondary"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          errorMsg={errors.email && touched.email ? errors.email : ""}
        />
        <Input
          label="Phone"
          name="phone"
          type="text"
          size="medium"
          placeholder="Enter Phone"
          variant="secondary"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          className=""
          errorMsg={errors.phone && touched.phone ? errors.phone : ""}
        />
        <TextArea
          label="Message"
          name="message"
          type="text"
          size="medium"
          placeholder="Enter Message"
          mandatory
          variant="primary"
          value={values.message}
          onChange={handleChange}
          onBlur={handleBlur}
          className="lg:col-start-1 lg:col-end-3 max-w-none mb-6"
          errorMsg={errors.message && touched.message ? errors.message : ""}
        />
        <Button type="submit" variant="secondary" size="small" className="">
          {loading ? "Sending..." : "Send Message"}
        </Button>
      </form>
    </div>
  );
};

// const Form = () => {
//   return (
//     <div className="w-full bg-white rounded-md px-6 py-8">
//       <Formik
//         initialValues={{
//           firstName: '',
//           lastName: '',
//           email: '',
//           phone: '',
//           message: '',
//         }}
//         onSubmit={(values) => {
//           console.log(values);
//         }}
//         validationSchema={contactUsSchema}
//       >
//         {({ handleSubmit }) => (
//           <form onSubmit={handleSubmit}>
//             <Input
//               label="First Name"
//               name="firstName"
//               type="text"
//               placeholder="Enter First Name"
//             />
//             <Input
//               label="Last Name"
//               name="lastName"
//               type="text"
//               placeholder="Enter Last Name"
//             />
//             <Input
//               label="Email"
//               name="email"
//               type="email"
//               placeholder="Enter Email"
//             />
//             <Input
//               label="Phone"
//               name="phone"
//               type="number"
//               placeholder="Enter Phone"
//             />
//             <Input
//               label="Message"
//               name="message"
//               type="text"
//               placeholder="Enter Message"
//             />
//             <Button type="submit">Submit</Button>
//           </form>
//         )}
//       </Formik>
//     </div>
//   );
// };

export default FormHeader;
