import HeaderImage from '../../../assets/images/about-us/header-image.svg';
import SplitScreens from '../../../components/splitScreens/spiltScreens';

const Intro = () => {
  return (
    <SplitScreens>
      <LeftScreen />
      <RightScreen />
    </SplitScreens>
  );
};

const LeftScreen = () => {
  return (
    <div className="md:w-[60%] max-w-[500px] flex-col-center">
      <h1 className="xl:text-6xl lg:text-[2.75rem] md:text-4xl sm:text-4xl text-[2rem] max-w-[600px] font-light leading-[1.2] tracking-wider">
        Empowering individuals to create positive change in their lives
      </h1>
    </div>
  );
};

const RightScreen = () => {
  return (
    <div className="xl:w-[40%] lg:w-[60%] md:w-[55%] max-w-[500px]">
      <img className="w-full" src={HeaderImage} alt="Header" />
    </div>
  );
};

export default Intro;
