import React from 'react';
import Product from './components/product';
import Description from './components/description';

const ProductsLayout = () => {
  return (
    <main>
      <section className="md:h-[300px] sm:h-[150px] h-[100px] flex-row-center bg-primary-yellow-light">
        <div className="container">
          <h1 className="lg:text-[7rem] md:text-7xl sm:text-4xl font-extrabold text-center text-white">
            Our Products
          </h1>
        </div>
      </section>
      <section>
        <div className="container py-8 sm:py-16 md:pb-40">
          <Description />
        </div>
      </section>
      <section>
        <div className="container">
          <Product />
        </div>
      </section>
    </main>
  );
};

export default ProductsLayout;
