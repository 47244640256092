import NotFoundVector from '../../assets/images/404.png'

const NotFound = () => {
    return (
        <div className="w-full max-h-fit p-12 flex-row-center">
            <img
                src={NotFoundVector}
                alt="not found"
                className="max-h-[500px]"
            />
        </div>
    );
};

export default NotFound