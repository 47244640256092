import EntwicklersLogo from '../../../assets/brandsLogo/entwicklers-black.png';
import GreatwayLogo from '../../../assets/brandsLogo/greatway-black.png';
import HumansOfIsroLogo from '../../../assets/brandsLogo/HOI_image-removebg-preview.png';
import EntwicklersCover from '../../../assets/brandsLogo/entwicklers-cover.png';
import GreatwayCover from '../../../assets/brandsLogo/greatway-cover.gif';
import HumansOfIsro from '../../../assets/brandsLogo/HOI-cover.png';

export const ProductData = [
  {
    id: 1,
    //name: 'Greatway',
    description: `Greatway is an innovative influencer portfolio platform that connects influencers with brands, simplifying the process of influencer marketing. It empowers influencers to showcase their work and enables brands to discover and collaborate with the perfect influencers for their campaigns. it also aids brands to curb fake and redundant account and links by having a single micro-landing site for everything.`,
    tagline: 'Empowering Voices, Simplifying Reach',
    image: GreatwayLogo,
    coverImage: GreatwayCover,
  },
  {
    id: 2,
    //name: 'Humans of ISRO',
    description: `Humans of ISRO is an innovative and captivating platform that seeks to showcase the human stories behind the remarkable achievements of the Indian Space Research Organisation (ISRO). Going beyond the technical and scientific aspects of space exploration, this initiative focuses on the people the scientists, engineers, and visionaries who bring the magic of the cosmos to life.`,
    tagline: 'Stories Beyond the Stars',
    image: HumansOfIsroLogo,
    coverImage: HumansOfIsro,
  },
  {
    id: 3,
    //name: 'Entwicklers',
    description: `Entwicklers is a community comprising programming enthusiasts, automation specialists, cloud computing experts, administrators, and security geeks from diverse locations. We arrange various technical events such as seminars, workshops, conferences, and training programs in numerous management and engineering colleges across India. Our goal is to narrow the divide between industry and academia, fostering connections between students and the latest technological advancements. We create interactive environments in colleges, enabling students to engage with the ongoing technological developments in our surroundings.`,
    tagline: 'Coming forward for a reason',
    image: EntwicklersLogo,
    coverImage: EntwicklersCover,
  },
];
