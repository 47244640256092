import { ProductData } from '../constant/constant';

const Description = () => {
  return (
    <div>
      <h2 className="text-2xl text-primary-black font-bold mb-6 sm:mb-12">
        Our Nu Beginnings
      </h2>
      <p className="leading-[1.8] tracking-wide mb-16 text-base lg:text-lg text-primary-black" align="justify">
        We are dedicated to empowering your journey towards growth, transformation, and success. Each product listed here is a reflection of our dedication to fostering growth, promoting continuous learning, and supporting you at every step of your journey. We prioritize quality, relevance, and impact, ensuring that our offerings align with current industry trends, best practices, and emerging opportunities.
        <br />
        We invite you to delve deeper, explore our offerings, and embark on a journey of growth, discovery, and success with The Nu Beginnings. Your aspirations are our priority, and we are committed to providing the products and services that empower you to thrive in today's dynamic landscape.
      </p>
      <div className="sm:flex justify-around hidden items-center">
        {ProductData.map((product) => {
          return (
            <img
              src={product.image}
              alt="logo"
              className="max-w-[200px] h-fit"
            />
          );
        })}
      </div>
    </div>
  );
};

export default Description;
