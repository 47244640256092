import { ClassNames } from '../../../utils/helper';

const TextArea = ({
  type = 'text',
  variant = 'primary',
  size = 'medium',
  label,
  mandatory,
  errorMsg,
  cols = 30,
  rows = 10,
  className,
  ...props
}) => {
  const getVariant = () => {
    switch (variant) {
      case 'primary':
        return 'textarea textarea-bordered w-full ';
      default:
        return 'bg-white border-2 border-red-500';
    }
  };

  const getSize = () => {
    switch (size) {
      case 'small':
        return 'textarea-sm';
      case 'medium':
        return 'textarea-md';
      case 'large':
        return 'textarea-lg';
      default:
        return 'text-base';
    }
  };

  return (
    <label className={ClassNames('form-control w-full relative', className)}>
      {label && (
        <div className="label">
          <span className="label-text">
            {label}
            {mandatory && <span className="ml-1 text-red-500 text-sm">*</span>}
          </span>
        </div>
      )}
      <textarea
        type={type}
        className={ClassNames(getVariant(), getSize())}
        {...props}
      ></textarea>
      {errorMsg && (
        <div className="label absolute bottom-[-25px]">
          <span className="label-text-alt text-red-500">{errorMsg}</span>
        </div>
      )}
    </label>
  );
};

export default TextArea;
