import SimpleSlider from '../../../components/simpleSlider/simpleSlider';
import Greatway from '../../../assets/images/GREATWAY BANNER-min.png';
import HumansOfIrso from '../../../assets/images/HUMANS OF ISRO BANNER-min.png';
// import GreatwayLogo from '../../../assets/brandsLogo/greatway-black.png';
// import EntWicklers from '../../../assets/brandsLogo/entwicklers-black.png';

const Brands = () => {
  return <BrandSlider />;
};

const BrandSlider = () => {
  return (
    <div className="w-full ">
      <SimpleSlider>
        <img
          className="w-full 2xl:max-h-[800px] max-h-[600px] object-cover"
          src={Greatway}
          alt=""
        />
        <img
          className="w-full 2xl:max-h-[800px] max-h-[600px] h-fit object-cover"
          src={HumansOfIrso}
          alt=""
        />
      </SimpleSlider>
    </div>
  );
};

// const BrandLogos = () => {
//   return (
//   );
// };

export default Brands;
