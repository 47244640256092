import SpiltScreens from '../../../components/splitScreens/spiltScreens';
import LandingPageImage from '../../../assets/images/landingPage.svg';

const Header = () => {
  return (
    <SpiltScreens>
      <LeftScreen />
      <RightScreen />
    </SpiltScreens>
  );
};

const LeftScreen = () => {
  return (
    <div className="md:w-[60%] sm:max-w-fit max-w-[450px]">
      <img
        src={LandingPageImage}
        alt="Landing page"
        className="w-full lg:p-10"
      />
    </div>
  );
};

const RightScreen = () => {
  return (
    <div className="md:w-[40%] flex-col-center gap-7">
      <h1 className="text-center text-[2rem] font-light lg:text-6xl md:text-4xl leading-[1.2]">
        evaluate | <br /> create | conserve
      </h1>

      <div className="flex-row-center w-[75%] px-4 gap-4 ">
        <span className="h-[2px] rounded w-full bg-slate-400"></span>
        <span className="text-slate-500">X&nbsp;X&nbsp;X</span>
        <span className="h-[2px] rounded w-full bg-slate-400"></span>
      </div>

      <h2 className="text-center lg:text-3xl md:text-xl tracking-wider leading-[1.6] max-w-[380px] text-xl">
        Empowering individuals to create positive change in their lives
      </h2>
    </div>
  );
};

export default Header;
