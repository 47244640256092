import { ClassNames } from "../../../utils/helper";

const Input = ({
  type = "text",
  variant = "primary",
  size = "medium",
  label,
  mandatory,
  errorMsg,
  className,
  ...props
}) => {
  const getVariant = () => {
    switch (variant) {
      case "primary":
        return "input input-bordered input-warning w-full max-w-xs";
      case "secondary":
        return "input input-bordered w-full max-w-xs";
      default:
        return "bg-white border-2 border-red-500";
    }
  };

  const getSize = () => {
    switch (size) {
      case "small":
        return "input-sm w-full max-w-xs";
      case "medium":
        return "input-md w-full max-w-xs";
      case "large":
        return "input-lg w-full max-w-xs";
      default:
        return "input-md w-full max-w-xs";
    }
  };

  return (
    <label className={ClassNames("form-control w-full max-w-xs", className)}>
      {label && (
        <div className="label">
          <span className="label-text">
            {label}
            {mandatory && <span className="ml-1 text-red-500 text-sm">*</span>}
          </span>
        </div>
      )}
      <input
        type={type}
        className={ClassNames(getVariant(), getSize())}
        {...props}
      />
      {errorMsg && (
        <div className="label pb-0">
          <span className="label-text-alt text-red-500">{errorMsg}</span>
        </div>
      )}
    </label>
  );
};

export default Input;
