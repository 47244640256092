import { ClassNames } from '../../utils/helper';

const SpiltScreens = ({ classNames, children }) => {
  const [left, right] = children;
  return (
    <div
      className={ClassNames(
        'flex w-full gap-4 h-full justify-center items-center flex-col md:flex-row',
        classNames
      )}
    >
      {left}
      {right}
    </div>
  );
};

export default SpiltScreens;
