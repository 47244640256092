import React from "react";
import Man from "../../assets/images/team/vector/Man.svg";
import Woman from "../../assets/images/team/vector/Woman.svg";
import greatway from "../../assets/images/Gretway_logo.png";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { FaBehance } from "react-icons/fa6";

export default function Team() {
  const Founder = [
    { name: "Aditya Prasun", subTitle: "Founder", gender: "M" },
    { name: "Vamika Shrivastava", subTitle: "MD", gender: "F" },
    { name: "Siddharth Anand", subTitle: "MD", gender: "M" },
    { name: "Tanmay Saha", subTitle: "Advisor", gender: "M" },
  ];

  const engineer = [
    { name: "Yash Mishra", subTitle: "Freelancer", gender: "M" },
    { name: "Aman Rai", subTitle: "Freelancer", gender: "M" },
    { name: "Rajesh Kumar Singh", subTitle: "Full stack Developer", gender: "M" },
    { name: "Gokul Jana", subTitle: "Full stack Developer", gender: "M" },
  ];
  const designer = [
    { name: "Anirban Mitra", subTitle: "Brand Stylist", gender: "M" },
    { name: "Komal Srivastava", subTitle: "UI/UX Designer", gender: "F" },
  ];

  return (
    <div className="h-full">
      <div className="w-full bg-primary-yellow-light to-orange-800 h-full">
        <div className="pt-[0%] lg:pt-[3%] xl:pt-[0%] h-fit w-full">
          <div className="text-center md:h-[300px] sm:h-[150px] h-[100px] flex-row-center bg-primary-yellow-light">
            <h1 className="lg:text-[7rem] md:text-7xl text-6xl font-extrabold text-center text-white">
              Our Team
            </h1>
          </div>
        </div>
      </div>
      <React.Fragment>
        <div className="md:p-24 px-2 py-10 flex justify-center">
          <p className="text-black text-xl sm:text-4xl w-full font-medium text-center tracking-wide">
            "Stronger Together: Where Expertise Meets Passion for
            Extraordinary Achievements."
          </p>
        </div>
        <div className="h-auto w-full flex flex-col pt-8 sm:pb-10 pb-4">
          <div className="text-[#F6BD4D] text-xl sm:text-4xl w-full flex justify-center text-center font-medium">
            Meet our Team Members
          </div>
          <div className="flex flex-col p-4">
            <div className="flex flex-col py-6">
              <span className="text-2xl py-2 font-bold sm:px-16 px-2 text-center underline-offset-1">
                Founding Team
              </span>
              <div className="w-full  xl:px-14 container py-4 grid lg:grid-cols-4  sm:grid-cols-2 grid-flow-row grid-cols-1">
                {Founder.map((itr, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col gap-1 w-full  justify-center h-fit"
                    >
                      {itr.gender === "M" ? (
                        <img
                          src={Man}
                          className="h-52 w-fit mx-auto rounded-3xl"
                          alt="person"
                        />
                      ) : (
                        <img
                          src={Woman}
                          className="h-52 w-fit mx-auto rounded-3xl"
                          alt="person"
                        />
                      )}
                      <div className="flex flex-col justify-end gap-2">
                        <div className="flex flex-col mt-1 gap-1">
                          <span className="w-full text-center text-xl font-medium whitespace-nowrap">
                            {itr.name}
                          </span>
                          <span className="w-full text-center text-sm text-[#0000008C] font-bold">
                            {itr.subTitle}
                          </span>
                        </div>

                        <div className="flex gap-x-5 justify-center items-center w-full md:px-0 ">
                          <img
                            src={greatway}
                            alt="icon"
                            className=" my-auto h-6"
                          />
                          <FaLinkedin className="my-auto " />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col py-6 items-center">
              <span className="text-2xl py-2 font-bold sm:px-16 px-2">
                Our Engineering Team
              </span>
              <div className="w-full gap-3 xl:px-14 container  py-4 grid lg:grid-cols-4  sm:grid-cols-2 grid-flow-row grid-cols-1">
                {engineer.map((itr, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col gap-1 w-full  justify-center h-fit"
                    >
                      {itr.gender === "M" ? (
                        <img
                          src={Man}
                          className="h-52 w-fit mx-auto rounded-3xl"
                          alt="person"
                        />
                      ) : (
                        <img
                          src={Woman}
                          className="h-52 w-fit mx-auto rounded-3xl"
                          alt="person"
                        />
                      )}
                      <div className="flex flex-col justify-end gap-2">
                        <div className="flex flex-col mt-1 gap-1">
                          <span className="w-full text-center text-xl font-medium whitespace-nowrap">
                            {itr.name}
                          </span>
                          <span className="w-full text-center text-sm text-[#0000008C] font-bold">
                            {itr.subTitle}
                          </span>
                        </div>
                        <div className="flex gap-x-5 justify-center items-center w-full">
                          <img
                            src={greatway}
                            alt="icon"
                            className="my-auto h-6"
                          />
                          <FaGithub className="my-auto" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-col py-6 items-center">
              <span className="text-2xl py-2 font-bold sm:px-16 px-2">
                Our Brand Stylist
              </span>
              <div className="  w-full container  py-4 grid grid-flow-row sm:grid-cols-2 grid-cols-1">
                {designer.map((itr, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col gap-1 w-full justify-center h-fit"
                    >
                      {itr.gender === "M" ? (
                        <img
                          src={Man}
                          className="h-52 w-fit mx-auto rounded-3xl"
                          alt="person"
                        />
                      ) : (
                        <img
                          src={Woman}
                          className="h-52 w-fit mx-auto rounded-3xl"
                          alt="person"
                        />
                      )}
                      <div className="flex flex-col justify-end gap-2">
                        <div className="flex flex-col mt-1 gap-1">
                          <span className="w-full text-center text-xl font-medium whitespace-nowrap">
                            {itr.name}
                          </span>
                          <span className="w-full text-center text-sm text-[#0000008C] font-bold">
                            {itr.subTitle}
                          </span>
                        </div>
                        <div className="flex gap-x-5 items-center justify-center">
                          <img
                            src={greatway}
                            alt="icon"
                            className=" my-auto h-6"
                          />

                          <FaBehance className="my-auto " />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}
