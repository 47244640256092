import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Home from "./pages/home";
import Product from "./pages/products";
import Contact from "./pages/contactUs";
import Teams from "./pages/teams";
import Career from "./pages/career/index";
import FAQ from "./pages/Faq/index";
import AboutUs from "./pages/aboutUs/index";
import Responsible from "./pages/responsible/index";
import Navbar from "./common/navbar/navbar";
import Footer from "./common/footer/footer";
import Blogs from "./pages/blogs";
import NotFound from "./pages/404";
import CompanyNews from "./pages/companyNews";
function App() {
  return (
    <div className="">
      <Toaster
        // position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: "2.5rem",
          },
          success: {
            style: {
              background: "white",
            },
          },
          error: {
            style: {
              background: "rgb(247,150,150)",
            },
          },
        }}
      />
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/products" element={<Product />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/responsible-disclosure" element={<Responsible />} />
          <Route path="/jobs" element={<Career />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="company-news" element={<CompanyNews />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
